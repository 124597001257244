.react-datepicker-custom {
  background-color: transparent !important;
}

.react-datepicker {
  font-family: inherit !important;
  border: 1px solid #e2e8f0 !important;
}

.react-datepicker__header {
  background-color: #f7fafc !important;
  border-bottom: 1px solid #e2e8f0 !important;
}

.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header {
  color: #2d3748 !important;
}

.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {
  color: #4a5568 !important;
}

.react-datepicker__day:hover {
  background-color: #edf2f7 !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
  background-color: #4299e1 !important;
  color: #ffffff !important;
}

/* Dark mode styles */
.dark .react-datepicker {
  background-color: #2d3748 !important;
  border-color: #4a5568 !important;
}

.dark .react-datepicker__header {
  background-color: #2d3748 !important;
  border-bottom-color: #4a5568 !important;
}

.dark .react-datepicker__current-month, 
.dark .react-datepicker-time__header, 
.dark .react-datepicker-year-header {
  color: #e2e8f0 !important;
}

.dark .react-datepicker__day-name, 
.dark .react-datepicker__day, 
.dark .react-datepicker__time-name {
  color: #e2e8f0 !important;
}

.dark .react-datepicker__day:hover {
  background-color: #4a5568 !important;
}

.dark .react-datepicker__day--selected, 
.dark .react-datepicker__day--keyboard-selected {
  background-color: #4299e1 !important;
  color: #ffffff !important;
}